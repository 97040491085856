import React from 'react';

export const WatchListStartEmpty = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="watchlist-star" stroke="#7B8A94">
          <path
            d="M9.83942653,1.78603677 C10.3198892,1.85984854 10.3997029,1.9396623 10.448368,2.03826861 L10.448368,2.03826861 L12.6068935,6.41191891 L17.4335032,7.11326635 C17.5701394,7.13312075 17.6857927,7.20647831 17.7623233,7.30903132 C17.838854,7.41158434 17.8762619,7.5433328 17.8564075,7.67996902 C17.8405953,7.78878753 17.7893517,7.88935874 17.7106099,7.96611304 L17.7106099,7.96611304 L14.2180423,11.370525 L15.0425257,16.1776319 C15.0658659,16.313716 15.0318376,16.4463775 14.9579531,16.5508531 C14.8840687,16.6553287 14.7703281,16.7316183 14.634244,16.7549586 C14.5258652,16.773547 14.4143812,16.7558897 14.3170509,16.7047201 L14.3170509,16.7047201 L10,14.4351121 L5.68294907,16.7047201 C5.56073792,16.7689703 5.42405395,16.7776021 5.30186022,16.7396186 C5.17966649,16.701635 5.07196299,16.6170361 5.00771279,16.494825 C4.95654326,16.3974947 4.93888592,16.2860107 4.95747434,16.1776319 L4.95747434,16.1776319 L5.7819577,11.370525 L2.28939013,7.96611304 C2.19051924,7.86973774 2.14007225,7.74241093 2.13843682,7.61446022 C2.13680138,7.48650951 2.18397749,7.35793491 2.2803528,7.25906401 C2.3571071,7.18032219 2.4576783,7.12907861 2.56649682,7.11326635 L2.56649682,7.11326635 L7.3931065,6.41191891 L9.55163196,2.03826861 C9.61273753,1.91445519 9.71824355,1.82713111 9.83942653,1.78603677 Z"
            id="star"
          />
        </g>
      </g>
    </svg>
  );
};

export const WatchListStartFull = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="页面-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="add-watchlist-star" fill="#00C6A2" stroke="#00C6A2">
          <path
            d="M9.83942653,1.78603677 C10.3198892,1.85984854 10.3997029,1.9396623 10.448368,2.03826861 L10.448368,2.03826861 L12.6068935,6.41191891 L17.4335032,7.11326635 C17.5701394,7.13312075 17.6857927,7.20647831 17.7623233,7.30903132 C17.838854,7.41158434 17.8762619,7.5433328 17.8564075,7.67996902 C17.8405953,7.78878753 17.7893517,7.88935874 17.7106099,7.96611304 L17.7106099,7.96611304 L14.2180423,11.370525 L15.0425257,16.1776319 C15.0658659,16.313716 15.0318376,16.4463775 14.9579531,16.5508531 C14.8840687,16.6553287 14.7703281,16.7316183 14.634244,16.7549586 C14.5258652,16.773547 14.4143812,16.7558897 14.3170509,16.7047201 L14.3170509,16.7047201 L10,14.4351121 L5.68294907,16.7047201 C5.56073792,16.7689703 5.42405395,16.7776021 5.30186022,16.7396186 C5.17966649,16.701635 5.07196299,16.6170361 5.00771279,16.494825 C4.95654326,16.3974947 4.93888592,16.2860107 4.95747434,16.1776319 L4.95747434,16.1776319 L5.7819577,11.370525 L2.28939013,7.96611304 C2.19051924,7.86973774 2.14007225,7.74241093 2.13843682,7.61446022 C2.13680138,7.48650951 2.18397749,7.35793491 2.2803528,7.25906401 C2.3571071,7.18032219 2.4576783,7.12907861 2.56649682,7.11326635 L2.56649682,7.11326635 L7.3931065,6.41191891 L9.55163196,2.03826861 C9.61273753,1.91445519 9.71824355,1.82713111 9.83942653,1.78603677 Z"
            id="star_bak"
          />
        </g>
      </g>
    </svg>
  );
};
