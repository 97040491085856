const en_US = {
  deposit: 'Deposit',
  Deposit: 'Deposit',
  withdraw: 'Withdraw',
  Withdraw: 'Withdraw',
  withdraw_token: 'Withdraw Token',
  swap: 'Swap',
  Swap: 'Swap',
  exchange_tokens: 'Exchange Tokens',
  pool: 'Pool',
  Pool: 'Pool',
  pools: 'Pools',
  Pools: 'Pools',
  Quiz: 'Quiz',
  no_tokens_deposited: 'No Tokens Deposited',
  view_pools: 'View Pools',
  view_pool: 'View Pool',
  add_token: 'Add Token',
  Add_Token: 'Add Token',
  create_new_pool: 'Create new pool',
  Create_New_Pool: 'Create New Pool',
  your_liquidity: 'Your Liquidity',
  Your_Liquidity: 'Your Liquidity',
  farms: 'Farms',
  Farms: 'Farms',
  airdrop: 'Airdrop',
  Airdrop: 'Airdrop',
  docs: 'Docs',
  Docs: 'Docs',
  community: 'Community',
  Community: 'Community',
  more: 'More',
  More: 'More',
  account: 'Account',
  Account: 'Account',
  New_ui: 'New UI',
  Risks: 'Risks',
  Forum: 'Forum',
  Discord: 'Discord',
  Telegram: 'Telegram',
  Twitter: 'Twitter',
  Medium: 'Medium',
  move_assets_to_from_ethereum: 'Move assets to/from Ethereum',
  rainbow_bridge: 'Rainbow Bridge',
  deposit_to_swap: 'Deposit to Swap',
  connect_to_near: 'Connect to NEAR',
  slippage: 'Slippage tolerance',
  slippage_title: 'Transaction Settings',
  minimum_received: 'Minimum received',
  swap_rate: 'Swap rate',
  swap_rate_pre: 'Swap rate',
  pool_fee: 'Pool fee',
  balance: 'Balance',
  from: 'From',
  to: 'To',
  submit: 'Submit',
  wrapnear: 'Wrap NEAR',
  wrapnear_tip_one: 'Wrapping NEAR allows you to trade on REF. Make sure to ',
  wrapnear_tip_two: 'leave 1 NEAR',
  wrapnear_tip_three: ' for gas fees to unwrap your NEAR.',
  wrap_error_msg: 'Not enough balance',
  sign_out: 'Sign Out',
  view_account: 'View Account',
  deposit_to_swap_and_add_liquidity: 'Deposit to swap and add liquidity',
  small_storage_fee_is_applied_of: 'Small storage fee is applied of',
  liquidity: 'Liquidity',
  tokens: 'Tokens',
  fee: 'Fee',
  fees: 'Fees',
  view_detail: 'View detail',
  pool_details: 'Pool details',
  details: 'Details',
  detail: 'Detail',
  liquidity_pools: 'Liquidity Pools',
  minimum_tokens_out: 'Minimum received',
  pair: 'Pair',
  tvl: 'TVL',
  search_pools: 'Search Pool',
  add_liquidity: 'Add Liquidity',
  remove_liquidity: 'Remove Liquidity',
  total_liquidity: 'Total liquidity',
  coming_soon: 'Coming soon',
  accumulated_volume: 'Accumulated Volume',
  underlying_liquidity: 'Underlying liquidity',
  total_shares: 'Total shares',
  my_shares: 'Shares',
  token: 'Token',
  enter_token_address: 'Enter token address',
  you_are_not_providing_liquidity_to_any_pools:
    'You aren’t providing liquidity to any pools',
  remove: 'Remove',
  select: 'Select',
  select_token: 'Select Token',
  basis_points: 'Basis points',
  total_fee: 'Total fee % ',
  protocol_fee_is: 'protocol fee is ',
  start_in: 'Start in',
  start_at: 'Start at',
  started_at: 'Started at',
  start_date: 'Start date',
  end_at: 'End at',
  end_date: 'End date',
  earn: 'Earn',
  ended: 'ENDED',
  pending: 'PENDING',
  rewards_per_week: 'Rewards per week',
  apr: 'APR',
  total_staked: 'Total staked',
  unclaimed_rewards: 'Unclaimed rewards',
  claim: 'Claim',
  claim_all: 'Claim All',
  stake: 'Stake',
  unstake: 'Unstake',
  your_rewards: 'Your rewards',
  your_shares: 'Shares',
  shares_owned: 'Shares Owned',
  stake_your_liquidity_provider_LP_tokens:
    'Stake your Liquidity Provider (LP) tokens',
  go_to_wallet: 'Go to Wallet',
  recent_activity: 'Recent Activity',
  view_all: 'View All',
  cancel: 'Cancel',
  claim_ref_token: 'Claim Ref Token',
  has_no_available_claim: 'has no available claim',
  sorry: 'Sorry',
  total_ref_token: 'Total Ref Token',
  locking_ref_token: 'Locked Ref Token',
  unclaim_ref_token: 'Unclaimed Ref Token',
  unlocked: 'Unlocked',
  locking: 'Locked',
  you_can_claim_from: 'You can claim from',
  you_have_claimed_all_your_rewards: 'You have claimed all your rewards',
  ends_in: 'Ends in',
  days: 'days',
  swap_successful_click_to_view: 'Swap successful. Click to view',
  claim_successful_click_to_view: 'Claim successful. Click to view',
  claim_failed_click_to_view: 'Claim failed. Click to view',
  slippageCopyForMobile:
    'Slippage means the difference between what you expect to get and what you actually get due to other executing first',
  slippageCopy:
    'Slippage means the difference between what <br> you expect to get and what you actually <br> get due to other executing first',
  depositCopy:
    'This deposits your selected tokens into the <br> exchange for swapping or adding to a liquidity pool',
  nearDepositCopy:
    'This first wraps then deposits your Ⓝ into the <br> exchange for swapping or adding to a liquidity pool',
  nearWithdrawCopy:
    'This will first unwrap your Ⓝ then withdraw it from <br> the exchange and move the tokens to your wallet',
  withdrawCopy:
    'This will withdraw your selected tokens from <br> the exchange and deposit them into your wallet',
  registerToken:
    'This registers a new token with the <br> exchange that is not already listed',
  whitelistTokenCopy: 'Add any NEP-141 token',
  addLiquidityPoolCopy:
    'This creates a new liquidity pool for the two tokens selected. The fee is the percentage the pool takes from each transaction',
  swapCopy:
    'Swap exchanges the first selected token with the second <br> selected token.The pools with the highest available liquidity and the lowest exchange fee will be used',
  addTokenCopy: 'Add any NEP-141 token',
  getLPTokenCopy: 'Click here to jump to the corresponding pool',
  airdropCopy:
    ' Please claim your airdrop at least once before the expiration date otherwise your balance would be donated into the treasury',
  farmRewardsCopy: 'Indicative value based on prices and not actual execution',
  totalValueLockedCopy: 'Total Value Locked',
  topPoolsCopy: 'For every pair, only display the highest pool TVL',
  you_do_not_have_enough: "You don't have enough",
  must_provide_at_least_one_token_for: 'Must provide greater than 0 token for',
  is_not_exist: 'is not exist',
  must_input_a_value_greater_than_zero: 'Must input a value greater than 0',
  amount_must_be_not_greater_your_balance:
    'Amount must be not greater than your balance ',
  no_pool_available_to_make_a_swap_from:
    'No pool available to make a swap from',
  for_the_amount: 'for the amount',
  no_pool_eng_for_chinese: ' ',
  is_not_a_valid_swap_amount: 'is not a valid swap amount',
  not_nep_address:
    'The address you entered is not a NEP-141 address, please check and enter it again. If it is another mainnet contract, go to Rainbow Bridge for bridging processing',
  not_correct_address:
    'The address you entered is incorrect, please check and enter',
  popular_tokens: 'Popular Tokens',
  asset_label: 'Asset',
  account_label: 'Account',
  total_label: 'Total',
  top_pools: 'Top pools',
  pool_id: 'Pool ID',
  more_pools: 'More pools',
  my_watchlist: 'My Watchlist',
  h24_volume: '24h volume',
  hide_low_tvl_pools: 'Hide low TVL',
  watchlist_title: 'My watchlist on the top',
  add_watchlist: 'Add Watchlist',
  remove_watchlist: 'Remove Watchlist',
  my_watchlist_copy: 'Marked pools will appear here',
  search_token: 'Search token',
  language: 'Language',
  deposit_to_add_liquidity: 'Deposit to Add Liquidity',
  slip_warn: 'Be careful, please check the minimum you can receive',
  slip_invalid: 'The slippage tolerance is invalid',
  input_to_search: 'Input to search',
  click_search_bar_to_search: 'Click search bar to search',
  reward_tokens: 'Reward tokens',
  detail_tip: 'Detail',
  no_data: 'No Data',
  stable_swap: 'StableSwap',
  exchange_rate: 'Exchange rate',
  including_fees: '(including fees)',
  standard_gas: 'Standard Gas fee:',
  rst_token: 'Your RST token',
  remove_tip: 'No fee in removing liquidity by share',
  share_liquidity: 'Share of liquidity',
  remove_token_confirm: 'You will remove RUST token',
  remove_as_one_token: 'Remove as one token',
  flexible_tip: 'Remove how much you want per token',
  add_type_all: 'Add all tokens in a balanced proportion',
  add_type_max: 'Use maximum amount of tokens available',
  select_balance: 'Select balance',
  selectBalanceCopy:
    'Select [NEAR wallet] to swap directly tokens available in your NEAR wallet',
  near_wallet: 'NEAR wallet',
  ref_account: 'REF account',
  swap_tip: 'Swap from your REF account by selecting REF account in settings',
  attention: 'Attention',
  live: 'Live',
  ended_search: 'Ended',
  unclaimed: 'Unclaimed',
  staked_only: 'Staked',
  sort_by: 'Sort by',
  your_farms_rewards: 'Your Farms Rewards',
  your_farms: 'Your farms',
  new: 'New',
  unstake_tip_t: 'you have rewards unclaimed...',
  unstake_tip_m:
    'Unstaking will remove the stake from all active and pending farms of the same pair.',
  unstake_tip_b: 'Do you want to unstake?',
  value_rewards: 'Claimed rewards',
  stake_unstake_tip:
    'Staking or unstaking will automatically claim your rewards.',
  over_tip:
    'Due to the limitation of Gas Fee, only 5 items most can be selected at one time',
  no_token_tip: 'No reward token',
  getToken_tip: 'You can claim your rewards or stake your LP Tokens now!',
  in_farm: 'in Farm',
  swap_rate_including_fee: 'Swap rate (including fees)',
  by_share: 'By Share',
  by_token: 'By Token',
  your_liquidity_positions_will_appear_here:
    'Your liquidity positions will appear here.',
  price_impact: 'Price impact',
  shares_left: 'Shares left',
  total_stable_coins: 'Total stablecoins',
  daily_volume: 'Daily volume',
  liquidity_utilisation: 'Liquidity utilisation',
  token_reserves: 'Token Reserves',
  roi_calculator: 'ROI Calculator',
  lp_staked: 'LP staked',
  usd: 'USD',
  lp_token: 'LP token',
  stake_for: 'Duration',
  day_1: '1D',
  day_2: '7D',
  day_3: '30D',
  day_4: '90D',
  day_5: '1Y',
  cur_apr: 'ROI',
  reward_token: 'Reward tokens',
  get_lp_token: 'Get LP shares',
  calculate_roi: 'ROI calculator',
  rewards_claimed: 'Rewards claimed',
  value_rewards_token: 'Value of rewards',
  all: 'all',
  all_5: '5 tokens per time',
  insufficient_shares: 'Insufficient shares',
  shares_removed: 'Shares',
  shares_avaliable: 'Shares avaliable',
  minimum_shares: 'Minimum shares',
  shares_tip: 'Shares available / shares you own totally',
  stablecoin_only: 'Stablecoin only',
  more_than: 'Please input number that more than or equal to 0.01',
  less_than: 'Please input number that less than 20',
  no_valid: 'Please input valid number',
  lp_fee: 'LP fee',
  protocol_fee: 'Protocol fee',
  referral_fee: 'Referral fee',
  total_fee_create: 'Total fee',
  xref_title: 'EARN MORE BY',
  xref_title1: 'Earn more',
  xref_title2: 'by staking REF for xREF',
  xref_introdution:
    'By staking REF, you have the opportunity to earn fees generated by the protocol. Any REF holders can have a share in the revenue earned by Ref Finance.',
  staking_apr: 'Staking APR',
  view_stats: 'View Stats',
  xref: 'xREF',
  ref: 'REF',
  more_than_general_seed:
    'Please input number that more than or equal to 0.000001',
  more_than_stable_seed: 'Please input number that more than or equal to 1',
  are_you_sure: 'Are you sure',
  price_impact_is_about: 'Price impact is about',
  yes_swap: 'Yes, swap',
  amount_must_be_greater_than_0: 'amount must be greater than 0',
  volume_ratio: '24h Volume/ Liquidity ratio',
  go_to_near_wallet: 'Go to NEAR Wallet',
  into_ref_account: 'into your REF account.',
  deposit_into_ref_account: 'into your REF account to add liquidity',
  deposit_near_tip: 'To deposit, keep at least 1 NEAR to cover gas fee',
  input_greater_than_available_shares: 'Input greater than available shares',
  number_of_unique_stakers: 'Number of Unique Stakers',
  total_ref_staked: 'Total REF Staked',
  total_xref_minted: 'Total xREF Minted',
  protocol_projected_revenue: 'Revenue Shared with xREF Holders',
  total_fee_Revenue_shared_with_xref_holders:
    'Total Fee Revenue Shared with xREF Holders',
  provision_treasury: 'Provision Treasury',
  protocol_projected_revenue_tip:
    'This number corresponds to the cumulative shared trading fee revenue to xREF holders. It is equal to 75% of the total platform fee revenue. It will differ from the actual REF token buyback amount due to price fluctuations.',
  stake_ref_to_xref_for_earning_more: 'Stake REF to xREF for earning more!',
  auto_router: 'Auto Router',
  auto_router_detail_sub_1: 'This route optimizes your swap by',
  auto_router_detail_sub_2: 'considering all pools for the same pair.',
  auto_router_detail_sub_3: 'Details:',
  introducing_parallel_swap: 'Introducing Parallel Swap',
  at_least: 'At least',
  xref_start_time: 'Starts on 1am UTC, 18th Jan, 2022.',
  xref_to_receive: 'xREF to receive',
  ref_to_receive: 'REF to receive',
  more_expensive_than_best_rate_en: 'more expensive than the best rate',
  optimal_path_found_by_our_solution: 'Optimal path found by our solution',
  check_to_stable_banner: 'Check',
  rates_to_stable_banner: 'Rates',
  make_sure_you_understand_what_you_do: 'Make sure you understand what you do',
  go_to_your_account_to: 'Go to your account to',
  deposit_l: 'deposit',
  withdraw_l: 'withdraw',
  got_it: 'Got it',
  check_the_transaction_settings: 'Check the transaction settings',
  filter_by: 'Filter by',
  allOption: 'All',
  stablecoin: 'Stablecoin',
  near_ecosystem: 'Near ecosystem',
  bridged_tokens: 'Bridged tokens',
  gaming: 'Gaming',
  nft: 'NFT',
  bridge: 'Bridge to/from',
  from_ethereum: 'Ethereum',
  from_aurora: 'Aurora',
  from_solana: 'Solana',
  from_terra: 'Terra',
  from_celo: 'Celo',
};
export default en_US;
