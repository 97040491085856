import React from 'react';

export function SmallWallet() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="4.5" width="16" height="11" rx="2.5" stroke="#7E8A93" />
      <path
        d="M11.2188 14H10.3088L6.64877 8.45002V14H5.73877V7.02002H6.64877L10.3088 12.56V7.02002H11.2188V14Z"
        fill="white"
      />
      <path
        d="M2 4.5L11.2703 1.55035C12.2515 1.23815 13.3094 1.72195 13.715 2.66837L14.5 4.5"
        stroke="#7E8A93"
      />
    </svg>
  );
}
