import React from 'react';

export function Swap() {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Account"
          transform="translate(-1148.000000, -339.000000)"
          fill="#ffffff"
          fillRule="nonzero"
        >
          <g id="exchange" transform="translate(1148.000000, 339.000000)">
            <path
              d="M8,0 C3.5816875,0 0,3.5816875 0,8 C0,12.4183125 3.5816875,16 8,16 C12.4183125,16 16,12.4183125 16,8 C16,3.5816875 12.4183125,0 8,0 Z M8,14.9090938 C4.18465625,14.9090938 1.09090625,11.8157031 1.09090625,8 C1.09090625,4.18429687 4.18465625,1.09090625 8,1.09090625 C11.8160625,1.09090625 14.9090938,4.18429688 14.9090938,8 C14.9090938,11.8157031 11.8160625,14.9090938 8,14.9090938 Z"
              id="shape"
            ></path>
            <path
              d="M7.67319273,9.09226623 L6.80800718,10.0357296 L6.80800718,4.5714274 C6.80800718,4.25596294 6.57329612,4 6.28399027,4 C5.99469943,4 5.75997336,4.25596294 5.75997336,4.5714274 L5.75997336,10.0835108 L4.89514806,9.13950733 C4.69011363,8.91703548 4.35817671,8.91703548 4.15416303,9.13986745 C3.94912859,9.3634524 3.94843809,9.72542135 4.15314228,9.94790957 L5.88246263,11.8336904 C5.96298131,11.9214776 6.06361465,11.9663289 6.16767049,11.984744 C6.17347973,11.9862336 6.17927395,11.9879033 6.18506817,11.9890164 C6.20656384,11.9921756 6.227369,11.9961042 6.24886467,11.9961042 C6.26081337,11.9970372 6.27207158,12 6.28400528,12 C6.46277056,12 6.62038543,11.9021458 6.71489431,11.7531546 L8.41419278,9.90066847 C8.61889697,9.67744364 8.61889697,9.31547469 8.41453803,9.09264272 C8.20914334,8.86943426 7.87720642,8.86868129 7.67319273,9.09226623 Z M11.117513,4.16629325 C11.0380301,4.07961909 10.9384025,4.03534079 10.8357276,4.01636906 C10.8282222,4.01451935 10.8207167,4.01227679 10.812881,4.01060715 C10.792406,4.00782441 10.7729669,4.00409226 10.752507,4.0039122 C10.740228,4.0031756 10.7286096,4 10.7159854,4 C10.5375653,4 10.3806259,4.09765779 10.2857868,4.2460924 L8.58579785,6.09970802 C8.38110867,6.32291647 8.38110867,6.6849018 8.58546761,6.90775013 C8.79084729,7.1305821 9.12279922,7.13133508 9.32681291,6.90811025 L10.1919835,5.9646633 L10.1919835,11.4285726 C10.1919835,11.7444299 10.4267095,12 10.7160004,12 C11.0053062,12 11.2400173,11.7444299 11.2400173,11.4285726 L11.2400173,5.91666922 L12.1048576,6.86049267 C12.309892,7.08296452 12.6418289,7.08296452 12.8458426,6.86049267 C13.050877,6.63690772 13.0515525,6.27456228 12.8468634,6.05209043 L11.117513,4.16629325 Z"
              id="shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function Withdraw() {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Account"
          transform="translate(-1148.000000, -379.000000)"
          fill="#ffffff"
          fillRule="nonzero"
        >
          <g id="exchange" transform="translate(1148.000000, 379.000000)">
            <path
              d="M8,0 C3.5816875,0 0,3.5816875 0,8 C0,12.4183125 3.5816875,16 8,16 C12.4183125,16 16,12.4183125 16,8 C16,3.5816875 12.4183125,0 8,0 Z M8,14.9090938 C4.18465625,14.9090938 1.09090625,11.8157031 1.09090625,8 C1.09090625,4.18429687 4.18465625,1.09090625 8,1.09090625 C11.8160625,1.09090625 14.9090938,4.18429688 14.9090938,8 C14.9090938,11.8157031 11.8160625,14.9090938 8,14.9090938 Z"
              id="shape"
            ></path>
            <path
              d="M8.68527333,4.16629325 C8.6057904,4.07961909 8.50616279,4.03534079 8.40348796,4.01636906 C8.39598249,4.01451935 8.38847702,4.01227679 8.38064131,4.01060715 C8.36016639,4.00782441 8.34072722,4.00409226 8.32026731,4.0039122 C8.30798836,4.0031756 8.29636989,4 8.28374569,4 C8.10532566,4 7.94838628,4.09765779 7.85354716,4.2460924 L6.15355819,6.09970802 C5.94886901,6.32291647 5.94886901,6.6849018 6.15322795,6.90775013 C6.35860763,7.1305821 6.69055956,7.13133508 6.89457325,6.90811025 L7.7597438,5.9646633 L7.7597438,11.4285726 C7.7597438,11.7444299 7.99446987,12 8.2837607,12 C8.57306655,12 8.80777761,11.7444299 8.80777761,11.4285726 L8.80777761,5.91666922 L9.67261792,6.86049267 C9.87765235,7.08296452 10.2095893,7.08296452 10.413603,6.86049267 C10.6186374,6.63690772 10.6193129,6.27456228 10.4146237,6.05209043 L8.68527333,4.16629325 Z"
              id="shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function RegisterToken() {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Account"
          transform="translate(-1148.000000, -420.000000)"
          fill="#ffffff"
          fillRule="nonzero"
        >
          <g id="exchange-2" transform="translate(1148.000000, 420.000000)">
            <path
              d="M8,0 C3.5816875,0 0,3.5816875 0,8 C0,12.4183125 3.5816875,16 8,16 C12.4183125,16 16,12.4183125 16,8 C16,3.5816875 12.4183125,0 8,0 Z M8,14.9090938 C4.18465625,14.9090938 1.09090625,11.8157031 1.09090625,8 C1.09090625,4.18429687 4.18465625,1.09090625 8,1.09090625 C11.8160625,1.09090625 14.9090938,4.18429688 14.9090938,8 C14.9090938,11.8157031 11.8160625,14.9090938 8,14.9090938 Z"
              id="shape"
            ></path>
            <path
              d="M7.19379845,11 C6.87131783,11 6.54883721,10.8784933 6.30077519,10.63548 L4.16744186,8.54556501 C3.94418605,8.32685298 3.94418605,7.96233293 4.16744186,7.71931956 C4.39069767,7.50060753 4.7627907,7.50060753 5.01085271,7.71931956 L7.14418605,9.80923451 C7.16899225,9.83353584 7.21860465,9.83353584 7.26821705,9.80923451 L10.9891473,6.16403402 C11.2124031,5.94532199 11.5844961,5.94532199 11.8325581,6.16403402 C12.055814,6.38274605 12.055814,6.7472661 11.8325581,6.99027947 L8.11162791,10.63548 C7.83875969,10.8784933 7.51627907,11 7.19379845,11 Z"
              id="shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function RemoveLiquidity() {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Account" transform="translate(-1147.000000, -460.000000)">
          <g id="Group-3" transform="translate(1147.060660, 460.974874)">
            <g
              id="exchange-3"
              transform="translate(0.939340, 0.025126)"
              fill="#ffffff"
              fillRule="nonzero"
            >
              <path
                d="M8,0 C3.5816875,0 0,3.5816875 0,8 C0,12.4183125 3.5816875,16 8,16 C12.4183125,16 16,12.4183125 16,8 C16,3.5816875 12.4183125,0 8,0 Z M8,14.9090938 C4.18465625,14.9090938 1.09090625,11.8157031 1.09090625,8 C1.09090625,4.18429687 4.18465625,1.09090625 8,1.09090625 C11.8160625,1.09090625 14.9090938,4.18429688 14.9090938,8 C14.9090938,11.8157031 11.8160625,14.9090938 8,14.9090938 Z"
                id="shape"
              ></path>
            </g>
            <line
              x1="6.43933983"
              y1="8.52512627"
              x2="12.4393398"
              y2="8.52512627"
              id="line-2"
              stroke="#ffffff"
              strokeLinecap="round"
            ></line>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function CloseIcon() {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99977 4.25444L0.899889 0.154551C0.800474 0.058533 0.667324 0.00540298 0.529117 0.00660396C0.39091 0.00780495 0.258703 0.0632409 0.160972 0.160972C0.0632409 0.258703 0.00780497 0.39091 0.00660398 0.529117C0.005403 0.667324 0.0585331 0.800474 0.154551 0.899889L4.25391 4.99977L0.154551 9.09966C0.105577 9.1486 0.0667215 9.20671 0.0402036 9.27066C0.0136857 9.33462 2.45072e-05 9.40317 3.29429e-08 9.47241C-2.44413e-05 9.54164 0.0135883 9.61021 0.040061 9.67418C0.0665336 9.73816 0.105348 9.79629 0.154287 9.84526C0.203227 9.89424 0.261334 9.93309 0.325289 9.95961C0.389245 9.98613 0.457798 9.99979 0.527034 9.99981C0.596269 9.99984 0.664832 9.98623 0.728806 9.95975C0.792781 9.93328 0.850915 9.89447 0.899889 9.84553L4.99977 5.74511L9.09966 9.84553C9.14863 9.8945 9.20678 9.93335 9.27076 9.95985C9.33475 9.98636 9.40333 10 9.47259 10C9.54185 10 9.61044 9.98636 9.67442 9.95985C9.73841 9.93335 9.79655 9.8945 9.84553 9.84553C9.8945 9.79655 9.93335 9.73841 9.95985 9.67442C9.98636 9.61044 10 9.54185 10 9.47259C10 9.40333 9.98636 9.33475 9.95985 9.27076C9.93335 9.20678 9.8945 9.14863 9.84553 9.09966L5.74511 4.99977L9.84553 0.899889C9.94436 0.800981 9.99986 0.666861 9.99981 0.527034C9.99976 0.387206 9.94417 0.253125 9.84526 0.154287C9.74636 0.0554494 9.61224 -4.93911e-05 9.47241 3.29824e-08C9.33258 4.94571e-05 9.1985 0.0556431 9.09966 0.154551L4.99977 4.25391V4.25444Z"
        fill="#7E8A93"
      />
    </svg>
  );
}

export function AddLiquidity() {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Account" transform="translate(-1148.000000, -501.000000)">
          <g id="Group-4" transform="translate(1148.000000, 501.000000)">
            <g id="exchange-4" fill="#ffffff" fillRule="nonzero">
              <path
                d="M8,0 C3.5816875,0 0,3.5816875 0,8 C0,12.4183125 3.5816875,16 8,16 C12.4183125,16 16,12.4183125 16,8 C16,3.5816875 12.4183125,0 8,0 Z M8,14.9090938 C4.18465625,14.9090938 1.09090625,11.8157031 1.09090625,8 C1.09090625,4.18429687 4.18465625,1.09090625 8,1.09090625 C11.8160625,1.09090625 14.9090938,4.18429688 14.9090938,8 C14.9090938,11.8157031 11.8160625,14.9090938 8,14.9090938 Z"
                id="shape"
              ></path>
            </g>
            <line
              x1="5.5"
              y1="8.5"
              x2="11.5"
              y2="8.5"
              id="line-2"
              stroke="#ffffff"
              strokeLinecap="round"
            ></line>
            <line
              x1="8.5"
              y1="5.5"
              x2="8.5"
              y2="11.5"
              id="line-2"
              stroke="#ffffff"
              strokeLinecap="round"
            ></line>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function AddPool() {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Account" transform="translate(-1148.000000, -541.000000)">
          <g id="Group-2" transform="translate(1148.000000, 541.000000)">
            <path
              d="M8,0 C3.5816875,0 0,3.5816875 0,8 C0,12.4183125 3.5816875,16 8,16 C12.4183125,16 16,12.4183125 16,8 C16,3.5816875 12.4183125,0 8,0 Z M8,14.9090938 C4.18465625,14.9090938 1.09090625,11.8157031 1.09090625,8 C1.09090625,4.18429687 4.18465625,1.09090625 8,1.09090625 C11.8160625,1.09090625 14.9090938,4.18429688 14.9090938,8 C14.9090938,11.8157031 11.8160625,14.9090938 8,14.9090938 Z"
              id="shape"
              fill="#ffffff"
              fillRule="nonzero"
            ></path>
            <circle id="oval" stroke="#ffffff" cx="8" cy="8" r="2.5"></circle>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function SwapBlack() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="iPhone-8backup"
          transform="translate(-20.000000, -172.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <g id="Group" transform="translate(20.000000, 172.000000)">
            <path
              d="M12,0 C5.37253125,0 0,5.37253125 0,12 C0,18.6274687 5.37253125,24 12,24 C18.6274687,24 24,18.6274687 24,12 C24,5.37253125 18.6274688,0 12,0 Z M12,22.3636406 C6.27698437,22.3636406 1.63635937,17.7235547 1.63635937,12 C1.63635937,6.27644531 6.27698437,1.63635937 12,1.63635937 C17.7240938,1.63635937 22.3636406,6.27644531 22.3636406,12 C22.3636406,17.7235547 17.7240938,22.3636406 12,22.3636406 Z"
              id="shape"
            ></path>
            <path
              d="M11.5097891,13.6383994 L10.2120108,15.0535943 L10.2120108,6.8571411 C10.2120108,6.38394441 9.85994417,6 9.4259854,6 C8.99204914,6 8.63996004,6.38394441 8.63996004,6.8571411 L8.63996004,15.1252663 L7.34272209,13.709261 C7.03517045,13.3755532 6.53726507,13.3755532 6.23124454,13.7098012 C5.92369289,14.0451786 5.92265714,14.588132 6.22971342,14.9218644 L8.82369394,17.7505356 C8.94447197,17.8822165 9.09542198,17.9494933 9.25150574,17.9771161 C9.26021959,17.9793504 9.26891092,17.9818549 9.27760226,17.9835245 C9.30984576,17.9882634 9.3410535,17.9941562 9.373297,17.9941562 C9.39122006,17.9955558 9.40810737,18 9.42600792,18 C9.69415585,18 9.93057815,17.8532187 10.0723415,17.6297319 L12.6212892,14.8510027 C12.9283455,14.5161655 12.9283455,13.973212 12.621807,13.6389641 C12.313715,13.3041514 11.8158096,13.3030219 11.5097891,13.6383994 Z M16.6762695,6.24943987 C16.5570451,6.11942864 16.4076037,6.05301119 16.2535914,6.02455359 C16.2423332,6.02177903 16.231075,6.01841519 16.2193215,6.01591072 C16.1886091,6.01173661 16.1594503,6.0061384 16.1287605,6.00586831 C16.110342,6.0047634 16.0929143,6 16.073978,6 C15.806348,6 15.5709389,6.14648669 15.4286802,6.3691386 L12.8786968,9.14956203 C12.571663,9.48437471 12.571663,10.0273527 12.8782014,10.3616252 C13.1862709,10.6958732 13.6841988,10.6970026 13.9902194,10.3621654 L15.2879752,8.94699495 L15.2879752,17.1428589 C15.2879752,17.6166449 15.6400643,18 16.0740005,18 C16.5079593,18 16.8600259,17.6166449 16.8600259,17.1428589 L16.8600259,8.87500384 L18.1572864,10.290739 C18.464838,10.6244468 18.9627434,10.6244468 19.2687639,10.290739 C19.5763156,9.95536158 19.5773288,9.41184342 19.270295,9.07813565 L16.6762695,6.24943987 Z"
              id="shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function WithdrawBlack() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="iPhone-8backup"
          transform="translate(-20.000000, -233.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <g id="exchange" transform="translate(20.000000, 233.000000)">
            <path
              d="M12,0 C5.37253125,0 0,5.37253125 0,12 C0,18.6274687 5.37253125,24 12,24 C18.6274687,24 24,18.6274687 24,12 C24,5.37253125 18.6274688,0 12,0 Z M12,22.3636406 C6.27698437,22.3636406 1.63635937,17.7235547 1.63635937,12 C1.63635937,6.27644531 6.27698437,1.63635937 12,1.63635937 C17.7240938,1.63635937 22.3636406,6.27644531 22.3636406,12 C22.3636406,17.7235547 17.7240938,22.3636406 12,22.3636406 Z"
              id="shape"
            ></path>
            <path
              d="M13.02791,6.24943987 C12.9086856,6.11942864 12.7592442,6.05301119 12.6052319,6.02455359 C12.5939737,6.02177903 12.5827155,6.01841519 12.570962,6.01591072 C12.5402496,6.01173661 12.5110908,6.0061384 12.480401,6.00586831 C12.4619825,6.0047634 12.4445548,6 12.4256185,6 C12.1579885,6 11.9225794,6.14648669 11.7803207,6.3691386 L9.23033728,9.14956203 C8.92330352,9.48437471 8.92330352,10.0273527 9.22984192,10.3616252 C9.53791145,10.6958732 10.0358393,10.6970026 10.3418599,10.3621654 L11.6396157,8.94699495 L11.6396157,17.1428589 C11.6396157,17.6166449 11.9917048,18 12.4256411,18 C12.8595998,18 13.2116664,17.6166449 13.2116664,17.1428589 L13.2116664,8.87500384 L14.5089269,10.290739 C14.8164785,10.6244468 15.3143839,10.6244468 15.6204044,10.290739 C15.9279561,9.95536158 15.9289693,9.41184342 15.6219356,9.07813565 L13.02791,6.24943987 Z"
              id="shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function RegisterTokenBlack() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="iPhone-8backup"
          transform="translate(-20.000000, -293.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <g id="exchange-2" transform="translate(20.000000, 293.000000)">
            <path
              d="M12,0 C5.37253125,0 0,5.37253125 0,12 C0,18.6274687 5.37253125,24 12,24 C18.6274687,24 24,18.6274687 24,12 C24,5.37253125 18.6274688,0 12,0 Z M12,22.3636406 C6.27698437,22.3636406 1.63635937,17.7235547 1.63635937,12 C1.63635937,6.27644531 6.27698437,1.63635937 12,1.63635937 C17.7240938,1.63635937 22.3636406,6.27644531 22.3636406,12 C22.3636406,17.7235547 17.7240938,22.3636406 12,22.3636406 Z"
              id="shape"
            ></path>
            <path
              d="M10.7906977,16.5 C10.3069767,16.5 9.82325581,16.31774 9.45116279,15.9532199 L6.25116279,12.8183475 C5.91627907,12.4902795 5.91627907,11.9434994 6.25116279,11.5789793 C6.58604651,11.2509113 7.14418605,11.2509113 7.51627907,11.5789793 L10.7162791,14.7138518 C10.7534884,14.7503038 10.827907,14.7503038 10.9023256,14.7138518 L16.4837209,9.24605103 C16.8186047,8.91798299 17.3767442,8.91798299 17.7488372,9.24605103 C18.0837209,9.57411908 18.0837209,10.1208991 17.7488372,10.4854192 L12.1674419,15.9532199 C11.7581395,16.31774 11.2744186,16.5 10.7906977,16.5 Z"
              id="shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function RemoveLiquidityBlack() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="iPhone-8backup" transform="translate(-20.000000, -355.000000)">
          <g id="Group-2" transform="translate(20.000000, 355.000000)">
            <g id="exchange-3" fill="#FFFFFF" fillRule="nonzero">
              <path
                d="M12,0 C5.37253125,0 0,5.37253125 0,12 C0,18.6274687 5.37253125,24 12,24 C18.6274687,24 24,18.6274687 24,12 C24,5.37253125 18.6274688,0 12,0 Z M12,22.3636406 C6.27698437,22.3636406 1.63635937,17.7235547 1.63635937,12 C1.63635937,6.27644531 6.27698437,1.63635937 12,1.63635937 C17.7240938,1.63635937 22.3636406,6.27644531 22.3636406,12 C22.3636406,17.7235547 17.7240938,22.3636406 12,22.3636406 Z"
                id="shape"
              ></path>
            </g>
            <line
              x1="7.71428571"
              y1="12.5"
              x2="16.2857143"
              y2="12.5"
              id="line-2"
              stroke="#FFFFFF"
              strokeWidth="2"
              strokeLinecap="round"
            ></line>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function AddLiquidityBlack() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="iPhone-8backup" transform="translate(-20.000000, -415.000000)">
          <g id="Group-3" transform="translate(20.000000, 415.000000)">
            <g id="exchange-4" fill="#FFFFFF" fillRule="nonzero">
              <path
                d="M12,0 C5.37253125,0 0,5.37253125 0,12 C0,18.6274687 5.37253125,24 12,24 C18.6274687,24 24,18.6274687 24,12 C24,5.37253125 18.6274688,0 12,0 Z M12,22.3636406 C6.27698437,22.3636406 1.63635937,17.7235547 1.63635937,12 C1.63635937,6.27644531 6.27698437,1.63635937 12,1.63635937 C17.7240938,1.63635937 22.3636406,6.27644531 22.3636406,12 C22.3636406,17.7235547 17.7240938,22.3636406 12,22.3636406 Z"
                id="shape"
              ></path>
            </g>
            <line
              x1="7.71428571"
              y1="11.7142857"
              x2="16.2857143"
              y2="11.7142857"
              id="line-2"
              stroke="#FFFFFF"
              strokeWidth="2"
              strokeLinecap="round"
            ></line>
            <line
              x1="11.7142857"
              y1="7.71428571"
              x2="11.7142857"
              y2="16.2857143"
              id="line-2"
              stroke="#FFFFFF"
              strokeWidth="2"
              strokeLinecap="round"
            ></line>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function AddPoolBlack() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="iPhone-8backup"
          transform="translate(-20.000000, -474.000000)"
          fill="#FFFFFF"
        >
          <g id="Group-4" transform="translate(20.000000, 474.000000)">
            <g id="exchange-5" fillRule="nonzero">
              <path
                d="M12,0 C5.37253125,0 0,5.37253125 0,12 C0,18.6274687 5.37253125,24 12,24 C18.6274687,24 24,18.6274687 24,12 C24,5.37253125 18.6274688,0 12,0 Z M12,22.3636406 C6.27698437,22.3636406 1.63635937,17.7235547 1.63635937,12 C1.63635937,6.27644531 6.27698437,1.63635937 12,1.63635937 C17.7240938,1.63635937 22.3636406,6.27644531 22.3636406,12 C22.3636406,17.7235547 17.7240938,22.3636406 12,22.3636406 Z"
                id="shape"
              ></path>
            </g>
            <circle id="oval" cx="12.5" cy="12.5" r="4.5"></circle>
          </g>
        </g>
      </g>
    </svg>
  );
}

function Default() {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Account"
          transform="translate(-1148.000000, -662.000000)"
          fill="#ffffff"
          fillRule="nonzero"
        >
          <g id="Group" transform="translate(1148.000000, 662.000000)">
            <g id="exchange-8">
              <path
                d="M8,0 C3.5816875,0 0,3.5816875 0,8 C0,12.4183125 3.5816875,16 8,16 C12.4183125,16 16,12.4183125 16,8 C16,3.5816875 12.4183125,0 8,0 Z M8,14.9090938 C4.18465625,14.9090938 1.09090625,11.8157031 1.09090625,8 C1.09090625,4.18429687 4.18465625,1.09090625 8,1.09090625 C11.8160625,1.09090625 14.9090938,4.18429688 14.9090938,8 C14.9090938,11.8157031 11.8160625,14.9090938 8,14.9090938 Z"
                id="shape"
              ></path>
            </g>
            <path
              d="M7.75,4 C8.12969577,4 8.44349096,4.28215388 8.49315338,4.64822944 L8.5,4.75 L8.5,8.75 C8.5,9.16421356 8.16421356,9.5 7.75,9.5 C7.37030423,9.5 7.05650904,9.21784612 7.00684662,8.85177056 L7,8.75 L7,4.75 C7,4.33578644 7.33578644,4 7.75,4 Z"
              id="line-3"
            ></path>
            <path
              d="M7.75,11 C8.12969577,11 8.44349096,11.2821539 8.49315338,11.6482294 L8.5,11.75 L8.5,12.75 C8.5,13.1642136 8.16421356,13.5 7.75,13.5 C7.37030423,13.5 7.05650904,13.2178461 7.00684662,12.8517706 L7,12.75 L7,11.75 C7,11.3357864 7.33578644,11 7.75,11 Z"
              id="line-3"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

function DefaultBlack() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="iPhone-8备份"
          transform="translate(-20.000000, -655.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <g id="exchange-8" transform="translate(20.000000, 655.000000)">
            <path
              d="M12,0 C5.37253125,0 0,5.37253125 0,12 C0,18.6274687 5.37253125,24 12,24 C18.6274687,24 24,18.6274687 24,12 C24,5.37253125 18.6274688,0 12,0 Z M12,22.3636406 C6.27698437,22.3636406 1.63635937,17.7235547 1.63635937,12 C1.63635937,6.27644531 6.27698437,1.63635937 12,1.63635937 C17.7240938,1.63635937 22.3636406,6.27644531 22.3636406,12 C22.3636406,17.7235547 17.7240938,22.3636406 12,22.3636406 Z"
              id="shape"
            ></path>
            <path
              d="M12,6 C12.506261,6 12.9246546,6.41040565 12.9908712,6.94287919 L13,7.09090909 L13,12.9090909 C13,13.5115834 12.5522847,14 12,14 C11.493739,14 11.0753454,13.5895944 11.0091288,13.0571208 L11,12.9090909 L11,7.09090909 C11,6.48841664 11.4477153,6 12,6 Z"
              id="line-3"
            ></path>
            <path
              d="M12,16 C12.506261,16 12.9246546,16.3385847 12.9908712,16.7778753 L13,16.9 L13,18.1 C13,18.5970563 12.5522847,19 12,19 C11.493739,19 11.0753454,18.6614153 11.0091288,18.2221247 L11,18.1 L11,16.9 C11,16.4029437 11.4477153,16 12,16 Z"
              id="line-3"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

function DepositBlack() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="iPhone-8backup"
          transform="translate(-20.000000, -717.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <g id="Group" transform="translate(20.000000, 717.000000)">
            <g id="exchange-9">
              <path
                d="M12,0 C5.37253125,0 0,5.37253125 0,12 C0,18.6274687 5.37253125,24 12,24 C18.6274687,24 24,18.6274687 24,12 C24,5.37253125 18.6274688,0 12,0 Z M12,22.3636406 C6.27698437,22.3636406 1.63635937,17.7235547 1.63635937,12 C1.63635937,6.27644531 6.27698437,1.63635937 12,1.63635937 C17.7240938,1.63635937 22.3636406,6.27644531 22.3636406,12 C22.3636406,17.7235547 17.7240938,22.3636406 12,22.3636406 Z"
                id="shape"
              ></path>
            </g>
            <path
              d="M12.5131953,6.60339552 C12.3913894,6.48029595 12.2387121,6.41740938 12.081365,6.39046464 C12.069863,6.38783759 12.058361,6.38465258 12.046353,6.38228125 C12.0149756,6.37832905 11.9851854,6.37302844 11.953831,6.37277271 C11.9350138,6.37172654 11.9172087,6.36721638 11.8978624,6.36721638 C11.6244373,6.36721638 11.3839309,6.50591553 11.2385919,6.71673081 L8.63339328,9.34934125 C8.31971126,9.66635461 8.31971126,10.1804668 8.63288719,10.4969687 C8.94762738,10.8134473 9.45633698,10.8145167 9.76898381,10.4974801 L11.0948401,9.15754305 L11.0948401,13.5556426 C11.0948401,14.0042412 11.4545531,14.3672164 11.8978854,14.3672164 C12.3412407,14.3672164 12.7009307,14.0042412 12.7009307,13.5556426 L12.7009307,9.08937913 L14.0262809,10.4298509 C14.340492,10.7458181 14.8491786,10.7458181 15.1618254,10.4298509 C15.4760365,10.1123029 15.4770717,9.59767923 15.1633897,9.28171204 L12.5131953,6.60339552 Z"
              id="shape"
              transform="translate(11.898068, 10.367216) rotate(-180.000000) translate(-11.898068, -10.367216) "
            ></path>
            <path
              d="M16.6807681,16.3672164 C17.0769221,16.3672164 17.3980685,16.8149316 17.3980685,17.3672164 C17.3980685,17.8581362 17.1443232,18.2664331 16.8097037,18.351105 L16.6807681,18.3672164 L8.11536887,18.3672164 C7.7192148,18.3672164 7.39806847,17.9195011 7.39806847,17.3672164 C7.39806847,16.8762966 7.65181372,16.4679996 7.98643322,16.3833277 L8.11536887,16.3672164 L16.6807681,16.3672164 Z"
              id="path-3"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

function Deposit() {
  return (
    <svg
      width="18px"
      height="16px"
      viewBox="0 0 18 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Account"
          transform="translate(-1147.000000, -703.000000)"
          fill="#ffffff"
          fillRule="nonzero"
        >
          <g id="Group-2" transform="translate(1147.500000, 703.000000)">
            <g id="exchange-9" transform="translate(0.500000, 0.000000)">
              <path
                d="M8,0 C3.5816875,0 0,3.5816875 0,8 C0,12.4183125 3.5816875,16 8,16 C12.4183125,16 16,12.4183125 16,8 C16,3.5816875 12.4183125,0 8,0 Z M8,14.9090938 C4.18465625,14.9090938 1.09090625,11.8157031 1.09090625,8 C1.09090625,4.18429687 4.18465625,1.09090625 8,1.09090625 C11.8160625,1.09090625 14.9090938,4.18429688 14.9090938,8 C14.9090938,11.8157031 11.8160625,14.9090938 8,14.9090938 Z"
                id="shape"
              ></path>
            </g>
            <path
              d="M9.18527333,3.53350963 C9.1057904,3.44683547 9.00616279,3.40255717 8.90348796,3.38358543 C8.89598249,3.38173573 8.88847702,3.37949317 8.88064131,3.37782353 C8.86016639,3.37504079 8.84072722,3.37130864 8.82026731,3.37112858 C8.80798836,3.37039197 8.79636989,3.36721638 8.78374569,3.36721638 C8.60532566,3.36721638 8.44838628,3.46487417 8.35354716,3.61330878 L6.65355819,5.46692439 C6.44886901,5.69013285 6.44886901,6.05211817 6.65322795,6.27496651 C6.85860763,6.49779848 7.19055956,6.49855146 7.39457325,6.27532663 L8.2597438,5.33187968 L8.2597438,8.4285726 C8.2597438,8.74442992 8.49446987,9 8.7837607,9 C9.07306655,9 9.30777761,8.74442992 9.30777761,8.4285726 L9.30777761,5.2838856 L10.1726179,6.22770905 C10.3776524,6.4501809 10.7095893,6.4501809 10.913603,6.22770905 C11.1186374,6.0041241 11.1193129,5.64177866 10.9146237,5.41930681 L9.18527333,3.53350963 Z"
              id="shape"
              transform="translate(8.783880, 6.183608) rotate(-180.000000) translate(-8.783880, -6.183608) "
            ></path>
            <path
              d="M11.8686487,9.88435541 C12.1447911,9.88435541 12.3686487,10.108213 12.3686487,10.3843554 C12.3686487,10.6298153 12.1917735,10.8339638 11.9585243,10.8762997 L11.8686487,10.8843554 L5.89806847,10.8843554 C5.62192609,10.8843554 5.39806847,10.6604978 5.39806847,10.3843554 C5.39806847,10.1388955 5.57494363,9.93474704 5.80819284,9.89241108 L5.89806847,9.88435541 L11.8686487,9.88435541 Z"
              id="path-3"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

const viewMap = {
  default: Default,
  'Storage Deposit': Deposit,
  Swap: Swap,
  Withdraw: Withdraw,
  'Register Tokens': RegisterToken,
  'Add Liquidity': AddLiquidity,
  'Remove Liquidity': RemoveLiquidity,
  'Add Pool': AddPool,
  Stake: AddLiquidity,
  Unstake: RemoveLiquidity,
  'Claim Reward By Seed': AddLiquidity,
  'Claim Reward By Farm': AddLiquidity,
  'Withdraw Reward': Withdraw,
  'Near Deposit': Deposit,
  Deposit: Deposit,
  'Instant swap': Swap,
  'Near Withdraw': Withdraw,
  'Add Stable Liquidity': AddLiquidity,
  'Remove Stable Liquidity': RemoveLiquidity,
  'xREF Unstake': RemoveLiquidity,
  'xREF Stake': AddLiquidity,
};

const blackViewMap = {
  default: DefaultBlack,
  'Storage Deposit': DepositBlack,
  Swap: SwapBlack,
  Withdraw: WithdrawBlack,
  'Register Tokens': RegisterTokenBlack,
  'Add Liquidity': AddLiquidityBlack,
  'Remove Liquidity': RemoveLiquidityBlack,
  'Add Pool': AddPoolBlack,
  Stake: AddLiquidityBlack,
  Unstake: RemoveLiquidityBlack,
  'Claim Reward By Seed': AddLiquidityBlack,
  'Claim Reward By Farm': AddLiquidityBlack,
  'Withdraw Reward': WithdrawBlack,
  'Near Deposit': DepositBlack,
  Deposit: DepositBlack,
  'Instant Swap': SwapBlack,
  'Near Withdraw': WithdrawBlack,
  'Add Stable Liquidity': AddLiquidityBlack,
  'Remove Stable Liquidity': RemoveLiquidityBlack,
  'xREF Unstake': RemoveLiquidityBlack,
  'xREF Stake': AddLiquidityBlack,
};

export function mapToView(action: string, black = false): JSX.Element {
  const map = black ? blackViewMap : viewMap;
  const View = map[action as keyof typeof viewMap] ?? map.default;
  return <View />;
}
